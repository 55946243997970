import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    marginTop: '50px',
    marginLeft: '50%',
    color: '#003f63',
  },
});

export default function ComponentOrSpinner({ isLoading, children }) {
  const classes = useStyles();

  return isLoading ? <CircularProgress className={classes.root} /> : children;
}
