import React, { PureComponent, Fragment } from 'react';
import { IntlProvider } from 'react-intl';
import { translationMessages } from 'i18n';
import { LOCALES } from 'utilities/constants';

export default class LanguageProvider extends PureComponent {
  state = { locale: 'da' };

  static getDerivedStateFromProps(props, state) {
    const receivedLocale = props.match.params.lang || props.defaultLang;
    return {
      locale: LOCALES.includes(receivedLocale) ? receivedLocale : state.locale,
    };
  }

  render() {
    return (
      <IntlProvider
        key={this.state.locale}
        locale={this.state.locale}
        messages={translationMessages[this.state.locale]}
        textComponent={Fragment}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }
}
