import { post, get } from 'utilities/request-utils';
import {
  USER_AUTHENTICATED,
  LOGIN_URI,
  LOG_OUT,
  DEBTOR_COOKIE_NAME,
} from 'utilities/constants';
import Cookies from 'universal-cookie';

export const getIsAuthenticated = () => get({ url: USER_AUTHENTICATED });
export const login = ({ returnPath }) =>
  window.location.assign(`/${LOGIN_URI}?returnPath=${returnPath}`);
export const logout = () => {
  const cookies = new Cookies();
  cookies.remove(DEBTOR_COOKIE_NAME, { path: '/' });
  return post({ url: LOG_OUT });
};

// for local testing purposes
// export const logout = () =>
//   new Promise((resolve, reject) => {
//     // eslint-disable-next-line no-console
//     console.log("Logging out...");
//     setTimeout(() => resolve(), 500);
//   });
