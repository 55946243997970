import React, { Component } from 'react';
import { getIsAuthenticated, login } from 'api/AuthApiHandler';
import ComponentOrSpinner from 'components/common/ComponentOrSpinner';

class AuthProvider extends Component {
  constructor(props) {
    super(props);
    this.state = { isAuthenticated: undefined };
  }

  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      getIsAuthenticated().then((result) =>
        this.setState({ isAuthenticated: result.data }),
      );
    } else {
      this.setState({ isAuthenticated: true });
    }
  }

  render() {
    if (this.state.isAuthenticated) {
      return this.props.children(true);
    } else if (this.state.isAuthenticated === false) {
      const { location } = this.props;
      login({ returnPath: `${location.pathname}${location.search}` });
    }
    return <ComponentOrSpinner isLoading={true} />;
  }
}

export default AuthProvider;
