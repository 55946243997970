import axios from 'axios';
import { BASE_URL } from 'utilities/constants';

const request = async ({ url, data, method }) => {
  const response = await axios({
    url: `${BASE_URL}${url}`,
    data,
    method,
    headers: {
      'Cache-Control':
        'max-age=0, private, no-cache, no-store, must-revalidate',
      Expires: '-1',
      Pragma: 'no-cache',
    },
  });

  if (response.status !== 200 && response.status !== 208) throw new Error();
  return response;
};

const post = ({ url, data }) =>
  request({
    url,
    data,
    method: 'post',
  });

const put = ({ url, data }) =>
  request({
    url,
    data,
    method: 'put',
  });

const remove = ({ url, data }) =>
  request({
    url,
    data,
    method: 'delete',
  });

const get = ({ url, data }) =>
  request({
    url,
    data,
    method: 'get',
  });

const patch = ({ url, data }) =>
  request({
    url,
    data,
    method: 'patch',
  });

export { post, put, get, patch, remove };
