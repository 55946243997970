import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { CssBaseline } from '@material-ui/core';
import ComponentOrSpinner from 'components/common/ComponentOrSpinner';
import LanguageProvider from 'providers/LanguageProvider';
import { defaultTheme } from './theme';
import { ProtectedRoute } from './protectedRoute';
import { routes } from './paths';
import 'fonts/fonts.css';

const App = () => (
  <ThemeProvider theme={defaultTheme}>
    <CssBaseline />
    <Suspense fallback={<ComponentOrSpinner isLoading />}>
      <Route
        render={() => (
          <Switch>
            {routes.map(({ protectedRoute, component, path, exact }) => {
              const RouteComponent = protectedRoute ? ProtectedRoute : Route;
              const Component = component;
              return (
                <RouteComponent
                  key={path}
                  path={path}
                  exact={exact}
                  protectedRoute={protectedRoute}
                  render={({ match, history, location, staticContext }) => (
                    <LanguageProvider match={match}>
                      <Component
                        match={match}
                        history={history}
                        location={location}
                        staticContext={staticContext}
                      />
                    </LanguageProvider>
                  )}
                />
              );
            })}
          </Switch>
        )}
      />
    </Suspense>
  </ThemeProvider>
);

export default App;
