import { lazy } from 'react';
const Maintenance = lazy(() => import('pages/Maintenance'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const ThankYou = lazy(() => import('pages/ThankYou/ThankYou'));
const Error = lazy(() => import('pages/Error/Error'));
const ESkatRedirect = lazy(() => import('pages/eskat/ESkatRedirect'));
const Flow = lazy(() => import('pages/Flow'));
const Welcome = lazy(() => import('pages/Welcome'));
const ConsentWizard = lazy(() => import('pages/ConsentWizard'));
const LogoutConfirmation = lazy(() => import('pages/LogoutConfirmation'));
const EskatRenew = lazy(() => import('pages/eskat/EskatRenew'));
const Consent = lazy(() => import('pages/eskat/Consent/Consent'));
const ApplicationWorkInProgress = lazy(() =>
  import('pages/ApplicationWorkInProgress'),
);
const ApplicationApproved = lazy(() => import('pages/ApplicationApproved'));
const ApplicationConfirmed = lazy(() => import('pages/ApplicationConfirmed'));
const ApplicationNoInformation = lazy(() =>
  import('pages/ApplicationNoInformation'),
);
const ConsentApplicationConfirmed = lazy(() =>
  import('pages/ConsentApplicationConfirmed'),
);

const paths = {
  maintenance: {
    path: '/maintenance',
  },
  home: {
    path: '/',
    langPath: '/:lang?/home',
    leasingPath: '/?productType=leasing',
    loanPath: '/?productType=kredit',
  },
  welcome: {
    path: '/:lang?/Welcome',
  },
  flow: {
    path: '/flow',
    buildPath: ({ debtorId, withGuarantor }) =>
      `/flow?dg=${debtorId}${withGuarantor ? '&sg=true' : ''}`,
  },
  consentWizzard: {
    path: '/:type/consent-wizard',
    buildPath: ({ productType, debtorId, withGuarantor }) =>
      `/${productType}/consent-wizard?dg=${debtorId}${
        withGuarantor ? '&sg=true' : ''
      }`,
  },
  applicationWorkInProgress: {
    path: '/work-in-progress',
  },
  applicationApproved: {
    path: '/approved',
  },
  applicationConfirmed: {
    path: '/confirmed',
  },
  applicationNoInformation: {
    path: '/no-information-available',
  },
  thankYou: {
    path: '/:lang?/thankYou',
  },
  eSkatRedirect: {
    path: '/:lang?/ESkatRedirect/:id',
  },
  logout: {
    path: '/logout',
    endFlowPath: '/logout?flowComplete=1',
  },
  error: {
    path: '/error',
  },
  consent: {
    path: '/consent',
  },
  eskatRenew: {
    path: '/eskat-renew',
  },
  consentApplicationConfirmed: {
    path: '/consent-confirmed',
  },
};

// Following should be uncommented when after release old applications are finished
// const Home = React.lazy(() => import("pages/Home"));

export const routes = [
  {
    component: Maintenance,
    exact: true,
    path: paths.maintenance.path,
    protectedRoute: false,
  },
  {
    component: Consent,
    exact: true,
    path: paths.home.path,
    protectedRoute: false,
    // For customers that will have started the old credit process just before the release
    // component: Home,
  },
  {
    component: Consent,
    exact: false,
    path: paths.home.langPath,
    protectedRoute: false,
    // For customers that will have started the old credit process just before the release
    // component: Home,
  },
  {
    component: Welcome,
    exact: false,
    path: paths.welcome.path,
    protectedRoute: false,
  },
  {
    component: Flow,
    exact: false,
    path: paths.flow.path,
    protectedRoute: true,
  },
  {
    component: ConsentWizard,
    exact: false,
    path: paths.consentWizzard.path,
    protectedRoute: true,
  },
  {
    component: ApplicationWorkInProgress,
    exact: false,
    path: paths.applicationWorkInProgress.path,
    protectedRoute: false,
  },
  {
    component: ApplicationApproved,
    exact: false,
    path: paths.applicationApproved.path,
    protectedRoute: false,
  },
  {
    component: ApplicationConfirmed,
    exact: false,
    path: paths.applicationConfirmed.path,
    protectedRoute: true,
  },
  {
    component: ApplicationNoInformation,
    exact: false,
    path: paths.applicationNoInformation.path,
    protectedRoute: false,
  },
  {
    component: ThankYou,
    exact: false,
    path: paths.thankYou.path,
    protectedRoute: true,
  },
  {
    component: ESkatRedirect,
    exact: false,
    path: paths.eSkatRedirect.path,
    protectedRoute: false,
  },
  {
    component: LogoutConfirmation,
    exact: false,
    path: paths.logout.path,
    protectedRoute: false,
  },
  {
    component: Error,
    exact: false,
    path: paths.error.path,
    protectedRoute: false,
  },
  {
    component: Consent,
    exact: false,
    path: paths.consent.path,
    protectedRoute: false,
  },
  {
    component: EskatRenew,
    exact: false,
    path: paths.eskatRenew.path,
    protectedRoute: true,
  },
  {
    component: ConsentApplicationConfirmed,
    exact: false,
    path: paths.consentApplicationConfirmed.path,
    protectedRoute: true,
  },
  {
    component: NotFound,
    exact: false,
    path: '/:lang?/*',
    protectedRoute: false,
  },
  {
    component: NotFound,
    exact: false,
    path: '*',
    protectedRoute: false,
  },
];

export default paths;
