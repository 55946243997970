/* eslint-disable no-undef */
export const BASE_URL = BASE_APP_URL;
export const SKAT_URL = SKAT_APP_URL;
export const NORDANIA_URL = NORDANIA_APP_URL;
export const SKAT_COOKIE_URL = SKAT_COOKIE_APP_URL;
/* eslint-enable */
export const LOGIN_URI = 'api/Authentication/login';
export const GET_TOKEN_URI = 'api/home/getToken';
export const USER_AUTHENTICATED = 'api/Authentication/IsAuthenticated';
export const LOG_OUT = 'api/Authentication/logout';
export const IMPORT_TOKEN_URL = 'api/home/ImportTaxData';
export const ESKAT_REDIRECT_URL = `${BASE_URL}ESkatConsent`;
export const ESKAT_URL = SKAT_URL;
export const ESKAT_COOKIE_URL = SKAT_COOKIE_URL;
export const CASE_INFO_URL = 'api/Cases';
export const GET_CUSTOMER_DATA_URL = 'api/Customer';
export const POST_CUSTOMER_DATA_URL = 'api/Customer';
export const GET_COMPANY_DATA_URL = 'api/Company';

export const LOCALES = ['da', 'en'];
export const PRODUCT_SUBTYPE_WITH_GUARANTOR = 'VendorLoan';
export const DEBTOR_COOKIE_NAME = 'debtorId';

export const CIVIL_STATUS_TYPES = [
  'Married',
  'Cohabiting',
  'Single',
  'UnderSeparation',
  'Widow_Widower',
];

export const RESIDENTIAL_STATUS_TYPES = [
  'Homeowner',
  'Tenant',
  'HousingCooperative',
  'ServiceTenancy',
  'LivingWithParents',
];

export const HOUSING_TYPES = [
  'DetachedHouse',
  'Terrace',
  'Apartment',
  'Room',
  'HolidayHouse',
  'HobbyFarm',
];

export const HALF_YEAR_INCOME_TYPES = ['Lower', 'Unchanged', 'Higher'];

export const CHILD_TYPES = [
  'Both',
  'Customer',
  'Cohabitant',
  'FosterChild',
  'TemporarilyInTheHousehold',
];

export const CHILD_RESIDENTIAL_STATUS_TYPES = [
  'LivingWithParents',
  'NotLivingWithParents',
];

export const CHILD_BIRTH_YEAR_COUNT = 19;
export const YES_NO_TYPES = [true, false];
export const VEHICLE_REPLACEMENT_PAYMENT_TYPES = ['0', '2000', '4000', '6000'];
export const DROPDOWN_UNSELECTED_VALUE = 'none';
export const ALL_EMPLOYMENT_TYPES = [
  'ContinuingEmployment',
  'SelfEmployed',
  'Student',
  'Pensioner',
  'TemporaryEmployment',
  'TerminatedEmployment',
  'ContractEmployment',
  'SeasonalEmployment',
  'Leave',
  'NoIncome',
  'UnemployedSocialSecurity',
  'Unemployed',
  'PostEmploymentBenefit',
  'EarlyRetirementPensioner',
];

export const DEFAULT_EMPLOYMENT_TYPES = [
  'ContinuingEmployment',
  'SelfEmployed',
  'Student',
  'Pensioner',
];

export const OTHER_EMPLOYMENT_TYPE = 'Other';

export const EMPLOYMENT_TYPES_REQUIRING_EMPLOYED_SINCE = [
  'ContinuingEmployment',
  'SelfEmployed',
  'TemporaryEmployment',
  'TerminatedEmployment',
  'ContractEmployment',
  'SeasonalEmployment',
];

export const HOUSING_EXPENSES_TYPES = ['Tenant', 'HousingCooperative'];
export const HOUSING_ROOM_TYPES = ['Apartment', 'Room'];

export const ESKAT_REDIRECT_ACTIONS = {
  HideIconAndText: 'HideIconAndText',
  GoForward: 'GoForward',
  GoToError: 'GoToError',
};

export const OWNERSHIP_TYPE_PRIVATELY_OWNED = 'Private';
export const OWNERSHIP_TYPE_LEASED = 'Leasing';
export const DEBT_TYPES = [
  'Mortgage',
  'BankLoan',
  'CooperativeHousingLoan',
  'CarLoan',
  'LoanforOtherAssets',
  'ConsumerLoan',
  'CreditCardOrOverdraftFacility',
  'StudentLoan',
  'OtherDebt',
];

export const PRODUCT_TYPES = {
  Leasing: 'Leasing',
  Loan: 'Loan',
};

export const AMOUNT_OF_LEASES = [1, 2];

export const EMPLOYMENT_TYPES_REQUIRING_EMPLOYED_FIELDS = [
  'ContinuingEmployment',
  'SelfEmployed',
  'TemporaryEmployment',
  'TerminatedEmployment',
  'ContractEmployment',
  'SeasonalEmployment',
];

export const LOCALE_FORMAT = 'DD/MM/YYYY';
