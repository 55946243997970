import { createTheme } from '@material-ui/core/styles';
import { theme as themeNS } from 'candy-shop-core';

const typo = {
  fontFamily: 'Danske Text, Verdana, sans-serif',
  fontSize: 16,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  h1: {
    fontFamily: 'Danske Human, Georgia, serif',
    lineHeight: 1.2,
    fontWeight: 400,
    fontSize: '2.75rem',
    color: '#003755',
  },
  h2: {
    fontFamily: 'Danske Text, Verdana, sans-serif',
    lineHeight: '34px',
    fontWeight: 700,
    fontSize: '24px',
    color: '#003755',
  },
  h3: {
    fontFamily: 'Danske Human, Georgia, serif',
    lineHeight: 1.2,
    fontWeight: 400,
    fontSize: '1.375rem',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    color: '#003755',
  },
  h5: {
    fontFamily: 'Danske Human, Georgia, serif',
    lineHeight: 1.2,
    fontWeight: 600,
    fontSize: '22px',
    color: '#003755',
  },
  h6: {
    fontFamily: 'Danske Text, Verdana, sans-serif',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '16px',
    color: '#406980',
  },
};

export const defaultTheme = createTheme({
  typography: typo,
  paper: {
    backgroundColor: 'white',
  },
  palette: {
    primary: {
      main: '#009edd',
    },
    text: {
      primary: '#003755',
    },
    secondary: {
      main: '#4caf50',
      contrastText: 'white',
    },
  },
  dynamicCentering: {
    display: 'table',
    margin: 'auto',
  },
  pageTitle: {
    textAlign: 'center',
    paddingTop: '3rem',
    paddingBottom: '2rem',
  },
  textCenter: {
    textAlign: 'center',
  },
  'MuiContainer-root': {
    padding: '0 0 0 0 !important',
    'padding-left': '0px !important',
  },
  MuiPaper: {
    root: {
      background: '#fff',
    },
  },
  maxFormWidth: {
    maxWidth: '435px',
  },
  maxFormLayoutWidth: {
    maxWidth: '750px',
  },
  icon: {
    height: '48px',
    marginTop: '10px',
  },
  separator: {
    margin: '16px 10px',
    height: '1px',
    borderWidth: 0,
    backgroundColor: '#e0e7ea',
  },

  pointer: { cursor: 'pointer' },
  ...themeNS.constructTheme({
    baseTheme: 'DanskeBank',
  }),
});
