import React from 'react';
import { Route } from 'react-router-dom';
import AuthProvider from './providers/AuthProvider';

export const ProtectedRoute = ({ render, computedMatch: match }) => (
  <Route
    render={(props) => (
      <AuthProvider location={props.location}>
        {(isAuthenticated) => render({ ...props, match, isAuthenticated })}
      </AuthProvider>
    )}
  />
);
